
    import { Component, Vue } from "vue-property-decorator";
    import { GraphUtils } from '../../ts/utils/GraphUtils';

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class EconomyHS2 extends Vue {
        private readonly commodity = {
            // tslint:disable
            content: [
                {
                    name:       'Hinkley Point nuclear plants',
                    price:      22000000000,
                    amount:     5,
                    icon:       'fa-radiation-alt',
                },
                {
                    name:       'Eurotunnels',
                    price:      9000000000,
                    amount:     12,
                    icon:       'fa-subway',
                },
                {
                    name:       'South Glasgow University Hospitals',
                    price:      842000000,
                    amount:     126,
                    icon:       'fa-hospital',
                },
            ],

            headers: [
                {
                    text:       'Name',
                    align:      'left',
                    sortable:   false,
                    value:      'name',
                },
                { text: 'Visualization',         value: 'icon' },
                { text: 'Price each',            value: 'price' },
            ],
            // tslint:enable
        };

        private readonly comparisonCostGraph = {
            // tslint:disable
            traces: [
                {
                    type: "bar",
                    x: ["Trident<br>nuclear<br>missiles", "US shuttle<br>programme<br>(30 years!)","HS2", "UK student<br>debt", "Eurotunnel", "HS1"],
                    y: [205000000000, 160000000000, 106000000000, 17000000000, 9000000000, 7300000000],
                    orientation: 'v',
                    marker: {
                        color: ['hsl(0, 100%, 35%)', 'hsl(26, 100%, 35%)', 'hsl(58, 100%, 35%)', 'hsl(110, 100%, 35%)', 'hsl(115, 100%, 35%)', 'hsl(120, 100%, 35%)'],
                    },
                }
            ],
            // tslint:enable
        };

        private readonly openingPhasesGraph = {
            // tslint:disable
            traces: [
                {
                    type: "bar",
                    x: [2024, 2029.5, 2033, 2037.5],
                    y: [8, 3, 4, 5],
                    width: [8, 3, 4, 5],
                    marker: {
                        color: ['hsl(0, 100%, 50%)', 'hsl(0, 100%, 30%)', 'hsl(240, 100%, 50%)', 'hsl(240, 100%, 30%)'],
                    },
                },
            ],
            // tslint:enable
        };

        // Methods
        private get createOpeningPhasesLayout(): object {
            // tslint:disable
            return GraphUtils.createLayout("Year", "Years taken", 72, 48);
            // tslint:enable
        }

        private get createCostGraphLayout(): object {
            // tslint:disable
            return GraphUtils.createLayout("", "(£) Billions", 72, 32);
            // tslint:enable
        }

        public data() {
            return {
                trainIconPathAndName:
                    require("@/assets/images/icon_train.png"),
            };
        }

        private getCommaSeparatedNumber(value: number): string {
            return Number(value).toLocaleString('en');
        }
    }
