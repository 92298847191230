import * as THREE from 'three';

// *********************************************************************************************************************
export class GraphUtils {
    // Methods
    public static createLayout(xAxisTitle: string, yAxisTitle: string, left: number = 72, bottom: number = 16): object {
        // tslint:disable
        return {
            showlegend:     false,
	        legend:         { orientation: "h" },
            xaxis:          { title: xAxisTitle, fixedrange: true, gridcolor:"hsla(0, 0%, 0%, 0.3)", rangeslider: { visible: false } },
            yaxis:          { title: yAxisTitle, fixedrange: true, gridcolor:"hsla(0, 0%, 0%, 0.3)" },

            paper_bgcolor:      "hsla(0, 100%, 20%, 0.0)",
            plot_bgcolor:       "hsla(0, 0%, 0%, 0.6)",

            height:             640,

            font: {
                size:           12,
                color:          "hsl(0, 0%, 100%)",
            },

            margin: {
                l:              left,
                r:              8,
                b:              bottom,
                t:              24,
                pad:            0
            }
        }
        // tslint:enable
    }
}
